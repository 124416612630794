import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Pane,
  ListItem,
  UnorderedList,
  majorScale,
  Paragraph,
} from 'evergreen-ui';
import AuthHeader from '../../auth-header/auth-header';
import ticketSirLogo from '../../../assets/images/ticketsir-logo.png';
import Skeleton from 'react-loading-skeleton';
import { WithSubdomainLink } from '../../../hooks/use-subdomain';
import Notification from '../../notification-bell/notification-bell';
import useSubdomain from '../../../hooks/use-subdomain';

export const PRIMARY_NAV_ITEMS = [
  {
    exact: true,
    shown: true,
    title: 'Home',
    path: '/',
  },
  {
    exact: true,
    shown: true,
    title: 'Events',
    path: '/search-events',
    // domainOnly: true,
  },
  {
    exact: false,
    shown: true,
    title: 'Create Event',
    path: '/host/create-event',
    domainOnly: true,
  },
];

const navStyle = {
  style: {
    display: 'inline-block',
    padding: '8px 0',
    color: '#fff',
    marginRight: '40px',
  },
  activeStyle: {
    borderBottom: '1px solid #fff',
  },
};

const DesktopHeader = ({ subdomainLogoUrl, loadingLogo }) => {
  const { subdomain } = useSubdomain();

  return (
    <Pane
      is="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      fontSize="0.9rem"
    >
      <NavLink to="/">
        {loadingLogo ? (
          <Paragraph>
            <Skeleton width={100} height={20} />
          </Paragraph>
        ) : (
          <img
            src={subdomainLogoUrl || ticketSirLogo}
            alt="Ticketsir logo"
            style={{ verticalAlign: 'middle', maxHeight: '60px' }}
          />
        )}
      </NavLink>

      <Pane
        is="nav"
        marginLeft={majorScale(5)}
        display="flex"
        alignItems="center"
        fontSize="1em"
      >
        <UnorderedList display="flex" alignItems="center">
          {PRIMARY_NAV_ITEMS.filter((item) => item.shown).map((navItem, i) => {
            if (navItem.domainOnly) {
              return (
                <ListItem key={i} fontSize="inherit">
                  <WithSubdomainLink
                    isNavLink
                    exact={navItem.exact}
                    to={navItem.path}
                    activeStyle={navStyle.activeStyle}
                    style={navStyle.style}
                  >
                    {navItem.title}
                  </WithSubdomainLink>
                </ListItem>
              );
            }
            return (
              <ListItem key={i} fontSize="inherit">
                <NavLink
                  exact={navItem.exact}
                  to={navItem.path}
                  activeStyle={navStyle.activeStyle}
                  style={navStyle.style}
                >
                  {navItem.title}
                </NavLink>
              </ListItem>
            );
          })}
        </UnorderedList>
        {!subdomain?.length && <Notification />}
        <AuthHeader />
      </Pane>
    </Pane>
  );
};

export default DesktopHeader;
